<template>
    <div class="clocker z-30 absolute">
        <modal :show="show_start_shift_modal">
            <startNewShiftModal @toggle="closeModals" />
        </modal>
        <modal :show="show_start_Event_modal">
            <startNewActivityModal @toggle="closeModals" :lob="lobId" />
        </modal>
        <modal :show="show_review_day_modal">
            <review-shift-modal :shift_id="last_shift" @toggle="show_review_day_modal = false" />
        </modal>
        <modal :show="show_today_modal" @close="closeModals">
            <todays-activity-modal @close="show_today_modal = false"/>
        </modal>

        <vs-sidebar
            class="clockerSidebar absolute inset-0"
            :click-not-close="notClose"
            position-right
            parent="body"
            v-model="active"
        >
            <button
                class="absolute top-2 left-2 bg-gray-200 px-1 rounded-full text-2xl z-10"
                @click="active = false"
                title="Hide Clocker"
            >
                <i class="bi bi-x"></i>
            </button>
            <h1
                class="absolute -bottom-2 left-0 right-0 text-center text-6xl m-0 font-bold tracking-tighter text-gray-100"
            >
                CLOCKER
            </h1>
            <div class="text-center">
                <h1 class="ctime text-6xl font-thin mt-3 mb-0">
                    {{ currentStringTime }}
                </h1>
                <h4 class="text-xl font-black">{{ currentDate }}</h4>
            </div>
            <div v-if="infoLoaded && currentActivity">
                <div v-if="clockInLoading" class="mb-3">
                    <b-spinner
                        variant="success"
                        type="grow"
                        label="Spinning"
                    ></b-spinner>
                </div>
                <div v-else>
                    <div class="text-xl flex justify-center mt-4">
                        <button
                            :disabled="currentActivity != 'None'"
                            class="bg-green-500 disabled:bg-green-200 text-white text-sm px-6 py-2 rounded-l-xl uppercase font-semibold flex"
                            @click="handleShowStartShift"
                        >
                            <i class="bi bi-stopwatch flex my-auto mr-1"></i>
                            Clock In
                        </button>
                        <button
                            :disabled="currentActivity == 'None'"
                            @click="clockOut()"
                            class="bg-red-500 disabled:bg-red-200 text-white text-sm px-6 py-2 rounded-r-xl uppercase font-semibold flex"
                        >
                            <i class="bi bi-slash-circle flex my-auto mr-1"></i>
                            Clock Out
                        </button>
                    </div>
                    <div class="text-xl flex justify-center mt-2 mb-4">
                        <button
                            :disabled="
                                currentActivity == 'None' &&
                                currentActivity != 'shift'
                            "
                            class="bg-blue-500 disabled:bg-blue-200 text-white text-sm px-6 py-2 rounded-l-xl uppercase font-semibold flex"
                            @click="handleShowStartEvent()"
                        >
                            <i class="bi bi-pause-circle flex my-auto mr-1"/>
                            Start Activity
                        </button>
                        <button
                            :disabled="
                                currentActivity == 'None' ||
                                currentActivity == 'shift'
                            "
                            class="bg-orange-400 disabled:bg-orange-200 text-white text-sm px-6 py-2 rounded-r-xl uppercase font-semibold flex"
                            @click="endActivity()"
                        >
                            <i class="bi bi-x-circle flex my-auto mr-1"></i>
                            End Activity
                        </button>
                    </div>
                </div>
                <div class="bg-gray-100 text-center px-3 py-2 rounded-lg mb-3">
                    <component
                        :key="'shift' + currentShiftKey"
                        :is="'currentShiftComponent'"
                    ></component>
                </div>
                <div class="bg-gray-100 text-center px-3 py-2 rounded-lg mb-3">
                    <component
                        :key="'current' + currentActivityKey"
                        :is="'currentActivityComponent'"
                    ></component>
                </div>
                <div class="text-center">
                    <button
                        @click="handleShowTodayModal"
                        class="bg-c-primary text-white text-sm px-6 py-2 rounded-xl uppercase font-semibold"
                    >
                        <div class="flex m-auto uppercase font-semibold">
                            <i class="bi bi-list-check mr-2 flex my-auto"/>
                            Today's Activity
                        </div>
                    </button>
                </div>
            </div>
            <div v-else class="absolute inset-0 bg-white grid content-center">
                <div class="text-center">
                    <p class="text-sm text-gray-400 leading-none mb-2">
                        We are loading<br />all our components...
                    </p>
                    <loader />
                </div>
            </div>
        </vs-sidebar>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import currentShiftComponent from "@/views/clocker/components/currentShift";
import currentActivityComponent from "@/views/clocker/components/currentActivity";
import TodaysActivityModal from "@/views/clocker/modals/todaysActivity";
import startNewActivityModal from "@/views/clocker/modals/startNewActivity";
import startNewShiftModal from "@/views/clocker/modals/startNewShift";
import ReviewShiftModal from "@/views/clocker/modals/ReviewShiftModal"
import auth from "@/config/user";
import Modal from "../Utilities/Modal";
import Loader from "@/views/components/Loader";
export default {
    components: {
        Modal,
        Loader,
        TodaysActivityModal,
        startNewShiftModal,
        startNewActivityModal,
        currentShiftComponent,
        currentActivityComponent,
        ReviewShiftModal
    },
    data() {
        return {
            currentShiftKey: 10,
            currentActivityKey: 20,
            ongoingActivityKey: 30,
            notClose: true,
            week: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
            infoLoaded: false,
            clockInDisabled: false,
            clockOutDisabled: false,
            startActivityDisabled: false,
            endActivityDisabled: false,
            ActDuration: null,
            clockInLoading: false,
            currentActivity: "None",
            currentDate: "",
            currentTime: null,
            currentStringTime: null,

            show_start_shift_modal: false,
            show_start_Event_modal: false,
            show_activities_modal: false,
            show_review_day_modal: false,
            show_today_modal: false,
            last_shift: null
        };
    },
    computed: {
        ...mapState(["clockerUtility", "clockerUsers", "schedules"]),
        ...mapGetters([
            "getCurrentActivity",
            "getCurrentShiftObject",
            "getStatusRequest",
            "getModalVisibility",
            "getNextEvent",
            "getCurrentTime",
        ]),
        lobId() {
            return this.getCurrentShiftObject?.lob_id;
        },
        activity: {
            get() {
                return this.getCurrentActivity;
            },
            set(val) {
                this.currentActivity = val;
            },
        },
        active: {
            get() {
                return this.getModalVisibility;
            },
            set(value) {
                this.$store.commit("setModalVisibility", value);
            },
        },
    },
    watch: {
        getCurrentActivity: function (val) {
            this.currentActivity = val.currentActivity;
        },
        active: function (newVal) {
            if (newVal) {
                this.currentShiftKey++;
                this.currentActivityKey++;
                this.ongoingActivityKey++;
            }
        },
    },
    methods: {
        ...mapActions([
            "loadCurrentActivity",
            "loadCurrentShiftObject",
            "clockInAgent",
            "endNewActivity",
            "clockOutAgent",
            "setModalVisibility",
            "fetchNextEvent",
            "getTimeFromServer",
        ]),

        zeroPadding: function (num, digit) {
            var zero = "";
            for (var i = 0; i < digit; i++) {
                zero += "0";
            }
            return (zero + num).slice(-digit);
        },

        updateTime: function () {
            try {
                var cd = this.currentTime;
                cd.setSeconds(cd.getSeconds() + 1);
                this.currentStringTime =
                    this.zeroPadding(cd.getHours(), 2) +
                    ":" +
                    this.zeroPadding(cd.getMinutes(), 2) +
                    ":" +
                    this.zeroPadding(cd.getSeconds(), 2);
            } catch (error) {
                console.warn("function updateTime is not working");
            }
        },

        checkNextEvent: function () {
            let requestData = {
                date: this.currentDate,
                time: this.currentTime,
                empID: auth.currentUser.EmpID,
            };
            this.fetchNextEvent(requestData).then((response) => {
                if (response.data.displayNotification == true) {
                    let nextEvent = response.data.event;
                    this.$toasted.show(
                        "This is a reminder: The event " +
                            nextEvent +
                            " will start soon, please open your clocker and attend the event.",
                        {
                            theme: "toasted-primary",
                            icon: "report",
                            position: "bottom-right",
                            duration: null,
                            action: {
                                text: "Ok!!",
                                onClick: (e, toastObject) => {
                                    toastObject.goAway(0);
                                },
                            },
                        }
                    );
                }
            });
        },

        handleShowStartShift() {
            this.active = false;
            this.show_start_shift_modal = true;
        },

        handleShowStartEvent() {
            this.active = false;
            this.show_start_Event_modal = true;
        },
        
        handleShowTodayModal() {
            this.active = false;
            this.show_today_modal = true;
        },

        handleTodaysActivity() {
            this.active = false;
            this.show_activities_modal = true;
        },

        closeModals: function () {
            this.show_start_shift_modal = false;
            this.show_start_Event_modal = false;
            this.show_activities_modal = false;
            this.show_review_day_modal = false;
            this.show_today_modal = false;
            this.active = true;
        },

        endActivity: function () {
            this.$confirm(
                `You're about to end the ${this.currentActivity} event`,
                "Do you want to continue?",
                "warning"
            ).then((result) => {
                if (result) {
                    this.infoLoaded = false;
                    this.endNewActivity(
                        this.getCurrentShiftObject.shift_id
                    ).then(() => {
                        this.$fire({
                            type: "success",
                            title: "Activity Ended Successfully",
                            showConfirmButton: false,
                            timer: 1300,
                        });
                        this.refreshActivities();
                    });
                }
            });
        },

        clockOut: function () {
            this.$confirm(
                "Press 'ok' if you want to continue",
                "You're about to clock out",
                "warning"
            ).then((result) => {
                if (result) {
                    this.infoLoaded = false;
                    this.last_shift = this.getCurrentShiftObject.shift_id;
                    let obj = {
                        shift_id: this.getCurrentShiftObject.shift_id,
                    };
                    this.clockOutAgent(obj).then(() => {
                        this.$fire({
                            type: "success",
                            title: "The shift has been ended",
                            showConfirmButton: false,
                            timer: 1300,
                        }).then(() => {
                            this.show_review_day_modal = true;
                            this.active = false;
                            this.refreshActivities();
                        });
                    });
                }
            });
        },

        refreshActivities: function () {
            this.currentShiftKey++;
            this.currentActivityKey++;
            this.ongoingActivityKey++;
            this.infoLoaded = true;
        },

        getBrowserInfo: function () {
            var ua = navigator.userAgent,
                tem,
                M =
                    ua.match(
                        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                    ) || [];
            if (/trident/i.test(M[1])) {
                tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
                return "IE " + (tem[1] || "");
            }
            if (M[1] === "Chrome") {
                tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
                if (tem != null)
                    return tem.slice(1).join(" ").replace("OPR", "Opera");
            }
            M = M[2]
                ? [M[1], M[2]]
                : [navigator.appName, navigator.appVersion, "-?"];
            if ((tem = ua.match(/version\/(\d+)/i)) != null)
                M.splice(1, 1, tem[1]);
            return M.join(" ");
        },
    },
    mounted: function () {
        this.getTimeFromServer().then(() => {
            let testDate = new Date(this.getCurrentTime.timeFromServer);
            let cd = new Date();
            this.currentDate =
                this.zeroPadding(cd.getDate(), 2) +
                "/" +
                this.zeroPadding(cd.getMonth() + 1, 2) +
                "/" +
                this.zeroPadding(cd.getFullYear(), 4) +
                " - " +
                this.week[cd.getDay()];
            testDate.setHours(this.getCurrentTime.hours);
            testDate.setMinutes(this.getCurrentTime.minutes);
            testDate.setSeconds(this.getCurrentTime.seconds);
            this.currentTime = new Date(testDate);
            setInterval(this.updateTime, 1000);
        });

        this.loadCurrentShiftObject().then(() => {
            this.currentShiftDuration =
                this.getCurrentShiftObject.currentShiftDuration;
        });

        this.loadCurrentActivity().then(() => {
            this.currentActivity = this.getCurrentActivity.currentActivity;
            this.infoLoaded = true;
        });
        setInterval(this.checkNextEvent, 300000);
    },
};
</script>
<style>
.vs-sidebar.vs-sidebar-position-right {
    min-width: 300px !important;
    width: auto !important;
    max-width: none !important;
    height: auto !important;
    padding: 40px 25px 60px;
    top: 5% !important;
    right: 30px !important;
    bottom: 5% !important;
    border-radius: 20px !important;
    -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 200 !important;
}
.vs-sidebar--background {
    z-index: 100 !important;
}
</style>